var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"export-product-ads-container elevation-5",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7","sm":"7","md":"3","lg":"3","xl":"3"}},[_c('h2',[_vm._v("Export Product Ads")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"5","sm":"5","md":"2","lg":"2","xl":"2"}},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.currencyToggle),callback:function ($$v) {_vm.currencyToggle=$$v},expression:"currencyToggle"}},_vm._l((_vm.currencyBtn),function(c,ind){return _c('v-btn',{key:ind},[_vm._v(" "+_vm._s(c.toUpperCase())+" ")])}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7","lg":"7","xl":"7"}},[_c('search-by-tags-and-brand',{attrs:{"append-icon":"mdi-magnify","only-brand":"","is-mobile":_vm.isMobile},on:{"on-search":function($event){return _vm.searchProducts()}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.products,"page":_vm.query.page,"items-per-page":_vm.query.perPage,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.query, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.photoUrls",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"product-image",attrs:{"src":item.photoUrls[0],"alt":item.name}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPrice(item.price))+" "+_vm._s(_vm.currencyIco[_vm.currencyToggle])+" ")]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag,index){return _c('v-chip',{key:("tag-chip-" + index),staticClass:"mr-1 mt-1",attrs:{"color":"secondary","text-color":"white","small":"","label":""}},[_vm._v(" "+_vm._s(tag)+" ")])})}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return _vm._l((item.categories),function(category,index){return _c('v-chip',{key:("category-chip-" + index),staticClass:"ma-1",attrs:{"color":"secondary","text-color":"white","small":"","label":""}},[_vm._v(" "+_vm._s(category)+" ")])})}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"7"},model:{value:(_vm.query.page),callback:function ($$v) {_vm.$set(_vm.query, "page", $$v)},expression:"query.page"}})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":!_vm.products.length,"color":"secondary"},on:{"click":function($event){return _vm.exportDataToCSV()}}},[_c('v-icon',[_vm._v("mdi-file-export")]),_vm._v(" Export CSV ")],1)],1),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }